.wrapper .btn-primary {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;

  &:hover {
    color: #fff;
    background-color: #0c83e2;
    border-color: #0c7cd5;
  }
}

.wrapper .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;

  &:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1;
  }
}

.wrapper .btn-primary:hover,
.wrapper .btn-primary:active,
.wrapper .btn-primary:focus,
.wrapper .content-wrapper .btn-primary:focus:active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #007dac;
  border-color: #007dac;
  box-shadow: none;
  color: #fff;
}

.btn-warning {
  color: #111;
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}

.btn-copy {
  color: #fff;
  background-color: @c-copy;
  border-color: @c-copy;

  &:hover {
    background-color: @c-copy-hover;
    border-color: @c-copy-hover;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 0, 255, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(81, 0, 255, 0.5);
  }
}

.btn-outline-copy {
  color: @c-copy;
  background-color: transparent;
  background-image: none;
  border-color: @c-copy;

  &:hover {
    color: #fff;
    background-color: @c-copy-hover;
    border-color: @c-copy-hover;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(81, 0, 255, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(81, 0, 255, 0.5);
  }

  &.disabled,
  &:disabled {
    color: @c-copy;
    background-color: transparent;
  }
}

.btn-secondary {
  color: #fff;
  background-color: @c-seconady;
  border-color: @c-seconady;
}

.btn-teal {
  color: #fff;
  background-color: @c-teal;
  border-color: @c-teal;

  &:hover {
    color: #fff;
    background-color: @c-teal-500;
    border-color: @c-teal-500;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(125, 223, 179, 0.5);
  }

  &:not(:disabled):not(.disabled):active {
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(125, 223, 179, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: @c-teal;
  }
}

.btn-space {
  margin-right: 5px;
}

a.btn-success {
  color: #fff;
}

.has-arrow {
  position: relative;

  .dropdown-toggle {
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 0;
    height: 12px;
    margin: 0 0 -8px;
    padding: 2px 10px;
    font-size: 18px;
    line-height: 0.5;
    color: @c-seconady;

    &:hover {
      color: #2b2b2b;
    }

    &.dropup {
      top: 10px;
      margin: -8px 0 0;

      &:after {
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
      }
    }
  }
}

.not-allowed {
  cursor: not-allowed;
}

#delete-selected {
  display: none;
}
