.page-link {
  color: #393d58;

  &:hover {
    color: #393d58;
  }
}

.page-item.active .page-link {
  border-color: @c-info;
  background: @c-info;
  border-radius: 0;

  &:hover {
    background-color: @c-info-hover;
    border-color: @c-info-hover;
    box-shadow: none;
    color: #fff;
  }
}
