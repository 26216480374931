/* Switch */

.switcher {
  padding: 0 5px;
}

.switcher input[type='checkbox'],
.switcher input[type='radio'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switcher label {
  position: relative;
  display: block;
  width: 48px;
  height: 28px;
  margin: 0 auto;
  background: #ccc;
  border-radius: 27px;
  text-indent: -9999px;
  cursor: pointer;
}

.switcher label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 22px;
  transition: 0.3s;
}

.switcher input:checked + label {
  background: @c-success;
}

.switcher input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.switcher input:disabled + label {
  cursor: not-allowed;
}

.switcher label:active:after {
  width: 25px;
}

fieldset:disabled .switcher label,
.switcher label.disabled {
  opacity: 0.35;
}

@-moz-document url-prefix() {
  select:not([multiple]) {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 129 129' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 129 129'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 20px 20px;
  }
}

.form-control {
  //border-radius: 0;
}

.form-control:active,
.form-control:focus {
  // outline: 2px solid #007dac;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #ced4da;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid,
.was-validated .form-control.not-valid,
.selectize-input.invalid {
  border-color: #f38b99;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus,
.was-validated .form-control.not-valid:focus,
.selectize-input.invalid:focus {
  border-color: #f38b99;
  box-shadow: 0 0 0 0.2rem rgba(230, 122, 133, 0.25);
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
}

input {
  font-size: inherit;
  margin: 0;
  padding: 0;
}

[data-audit-trail-update] {
  border: 3px double darkorange !important;
  cursor: not-allowed;
  padding: 3px;

  input:checked + & {
    background: lightgreen !important;

    &:after {
      left: calc(100% - 1px) !important;
    }
  }

  &:after {
    top: 0 !important;
    left: 1px !important;
  }
}

[data-audit-trail-create] {
  border: 3px double green !important;
  cursor: not-allowed;

  input:checked + & {
    background: lightgreen !important;

    &:after {
      left: calc(100% - 1px) !important;
    }
  }

  &:after {
    top: 0 !important;
    left: 1px !important;
  }

  & + label {
    border: 3px double green;
    cursor: not-allowed;
  }
}

[data-audit-trail-delete] {
  border: 3px double red !important;
  cursor: not-allowed;

  input:checked + & {
    background: lightgreen !important;

    &:after {
      left: calc(100% - 1px) !important;
    }
  }

  &:after {
    top: 0 !important;
    left: 1px !important;
  }

  & + label {
    border: 3px double red;
    cursor: not-allowed;
  }
}

.input-group-addon {
  display: table-cell;
  padding: 6px 12px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: nowrap;
  vertical-align: middle;
}
