.table-edit-dependencies {
  .select-wrapper {
    display: flex;
    flex-direction: row;
  }

  .switcher {
    width: 100%;
    text-align: center;
  }
}
