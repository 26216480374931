.security-pages,
.errors-pages {
  position: relative;
  //background: url(../img/bg_signin.jpg) center no-repeat;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  //overflow-y: scroll;
  background-color: #3498db;

  @media @mobile-landscape {
    height: auto;
  }

  .alert {
    color: @c-danger;
  }

  .block-form {
    border-radius: 0.25rem;
  }

  form {
    max-width: 380px;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .block-form {
    max-width: 380px;
    width: 100%;
    text-align: center;
    background-color: #fff;

    .brand-image-wrapper {
      text-align: center;
      margin: 0 auto 25px;
    }

    .brand-image {
      float: none;
      margin: 0 auto;
      line-height: 1;
      max-width: 100%;
    }

    .inner {
      padding: 30px;
    }

    .input-wrap {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 18px;
        bottom: 0;
        width: 10px;
        height: 18px;
        margin: auto;
        //font-family: @ff-icon;
        font-size: 18px;
        line-height: 18px;
      }

      &:nth-child(1):before {
        //.icon-user();
      }

      &:nth-child(2):before {
        //.icon-padlock();
      }
    }

    h1 {
      padding: 15px 0;
      font-weight: 400;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background-color: @c-info;
    }

    .sub {
      padding: 10px 0;
      font-size: 16px;
      color: #a9a9a9;
    }

    form {
      max-width: 220px;
      //margin: 30px 50px;
      margin: 30px auto;
    }

    footer {
      display: flex;
      justify-content: center;

      a {
        font-size: 12px;
        color: #222;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.errors-pages {
  .block-form {
    max-width: 480px;
  }
}
