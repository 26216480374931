/* ---------- GENERAL ---------- */

html {
  overflow-y: scroll;
}

body.modal-open {
  //overflow-y: scroll !important;
  padding-right: 0 !important;
}

.content-wrapper {
  background-color: #f2f1ed;
}

.container {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid {
  padding-bottom: 15px;

  @media @mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.col-sm {
  @media @mobile {
    margin-bottom: 0.25rem;
  }
}

.app-links {
  padding-top: 10px;
}

/* Alerts */

.alert {
  margin-bottom: 0;
  margin-top: 1rem;
  //border-radius: 0;
}

.alert-error {
  .alert-danger;
}

.table td,
.table th {
  border-top: 0;
}

.table td input[type='number'] {
  padding: 0 5px;
}

.table {
  table-layout: fixed;

  @media @mobile {
    min-width: 800px;
    width: auto;

    td,
    th {
      white-space: normal !important;
    }
  }
}

.content-wrapper {
  position: relative;
}

.required-star {
  font-size: 15px;
  line-height: 0.8;
  color: #dc3545;
}

.full-page-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.table td input[type='number'].new_position {
  padding: 0.375rem 0.75rem;
}

.table th.vertical-align-top {
  vertical-align: top !important;
}

.selectize-dropdown {
  width: auto !important;
  min-width: 100% !important;

  .option:first-of-type {
    white-space: nowrap !important;
  }
}
