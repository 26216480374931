//@font-face {
//  font-family: 'Source Sans Pro';
//  src: url('../../fonts/SourceSansPro-Regular.woff2') format('woff2');
//  font-weight: normal;
//  font-style: normal;
//
//}
//
//@font-face {
//  font-family: 'Source Sans Pro';
//  src: url('../../fonts/SourceSansPro-Bold.woff2') format('woff2');
//  font-weight: bold;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Source Sans Pro';
//  src: url('../../fonts/SourceSansPro-Light.woff2') format('woff2');
//  font-weight: 300;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Source Sans Pro';
//  src: url('../../fonts/SourceSansPro-Italic.woff2') format('woff2');
//  font-weight: normal;
//  font-style: italic;
//}


.font-face(@family-name, @font-path, @weight: normal, @style: normal) {
  @font-face {
    font-family: @family-name;
    src: url('../../fonts/@{font-path}.woff') format('woff');
    font-weight: @weight;
    font-style: @style;
  }
}

.font-face('Gotham', "GothamBook", @weight: 400, @style: normal);
.font-face('Gotham', "GothamMedium", @weight: 500, @style: normal);
.font-face('Gotham', "GothamBold", @weight: 700, @style: normal);
.font-face('Gotham', "GothamLight", @weight: 300, @style: normal);