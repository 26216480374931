.color-success {
  color: @c-success;
}

.color-teal {
  color: @c-teal;
}

.color-brown-500 {
  color: @c-brown-500;
}

.color-deep-orange-500 {
  color: @c-deep-orange-500;
}

.color-red-light {
  color: #ff473d;
}

.color-red-light {
  color: #ff473d;
}

.color-blue-500 {
  color: @c-blue-500;
}

.color-light-blue-500 {
  color: @c-light-blue-500;
}

.color-pink-500 {
  color: @c-pink-500;
}

.color-purple-500 {
  color: @c-purple-500;
}

.color-indigo-500 {
  color: @c-indigo-500;
}
