/* Main header CSS */

.main-sidebar {
  z-index: 1100;
}

.main-sidebar .brand-image {
  float: none;
  max-width: 100%;
  width: 136px;
  height: 34px;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}

.sidebar-mini.sidebar-collapse .brand-image {
  width: 40px;
  height: 10px;
}

.main-sidebar .brand-link {
  height: 54px;
  padding: 11px 10px 10px 0;
  text-align: center;
}

.navbar-expand .navbar-nav .dropdown-menu {
  border-radius: 0;
}

/* Sidebar CSS */

.sidebar-dark-primary {
  //background: #121426;
  background-color: #36384c;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link {
  transition: all 0.2s;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link:hover p,
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  color: #fff;
}

.sidebar-dark-primary .nav-sidebar > .nav-item:hover > .nav-link,
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link:hover {
  background: none;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-pills {
  .nav-link {
    border-radius: 0;
  }
}

.sidebar-light-primary {
  .sidebar {
    a {
      color: #585b5d;
    }
  }

  .nav-sidebar {
    & > .nav-item {
      & > .nav-link {
        &.active {
          position: relative;
          box-shadow: none;
          color: #585b5d;
          border-radius: 0;
          background-color: #f4f4f5;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 3px;
            height: 100%;
            background-color: #585b5d;
          }
        }
      }
    }
  }
}
