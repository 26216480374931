.card-title {
  margin-bottom: 0;
}

.card .card-header {
  padding: 12px 20px 13px;
}

.card-info:not(.card-outline) .card-header {
  background-color: #007dac;
}

.card {
  border-radius: 2px;
  border: none;
}

.card-header {
  border-radius: 0;
  border-bottom: 1px solid #dbdbdb;
}

.card-primary:not(.card-outline) .card-header {
  background-color: #4a71b9;
}
