.focus-table {
  tbody {
    tr {
      &.focus-in {
        background-color: #d0e7fb;
      }

      td {
        input,
        select {
          white-space: nowrap;

          &.not-valid {
            // outline: 0px solid #f38b99;
          }
        }
      }
    }
  }
}

.campaign-placement-editable-container {
  &.template-readonly {
    select,
    input {
      pointer-events: none;
      //background-color: #e9ecef;
      //opacity: 1;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.template-active {
    .editable-input-wrapper {
      &:hover {
        .edit-name-button {
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }
      }

      &.focus-in {
        flex-grow: 1;

        .tick,
        .cancel {
          display: block;
        }

        .edit-name-button {
          display: none;
        }
      }
    }

    .editable-work-name:not(.not-valid):not(:invalid):not(:disabled) {
      &:hover {
        border-color: #dde9fa;
      }

      &:focus {
        border-color: #80bdff;
      }
    }

    .editable-table {
      td {
        .close-manual {
          i {
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .card-title {
    @media @mobile {
      line-height: 1.5;
    }

    .badge {
      vertical-align: middle;
      margin: 8px 0 0 10px;
      padding: 3px 4px;
      font-size: 0.8rem;
      font-weight: 500;
      white-space: normal;
    }

    &.with-editable-input {
      padding-right: 50px;
      width: 95%;
    }

    .title {
      width: 70px;
      vertical-align: middle;
    }

    .editable-input-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      .tick,
      .cancel {
        display: none;
      }
    }

    .tick,
    .cancel {
      position: absolute;
      top: 100%;
      right: 30px;
      width: 24px;
      height: 24px;
      font-size: 13px;
      border: 1px solid #e4e4e4;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;

      transition: background-color 0.1s;

      &:hover {
        background-color: #efefef;
      }

      &:after {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        content: '\f00c';
        color: #666;
        width: 14px;
        height: 16px;
        margin: -8px 0 0 -7px;
        .fa;
        line-height: 16px;
        font-family: 'FontAwesome';
      }
    }

    .cancel {
      right: 3px;

      &:after {
        margin: -9px 0 0 -7px;
        font-size: 16px;
        content: '\f00d';
      }
    }

    .form-control:not(.selectize-control) {
      display: inline-block;
      vertical-align: middle;
      padding: 0.175rem 2.15rem 0.175rem 0.65rem;
    }

    .editable-work-name {
      background-color: transparent;
      border-color: transparent;
      width: 100%;
      font-size: 1.25rem;
      line-height: 22px;
    }
  }

  .card-body + .card-body {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .result-block {
    position: relative;

    & > .card-tools {
      position: absolute;
      display: flex;
      right: 1rem;
      top: 1rem;
      align-items: center;
      gap: 10px;
    }

    .with-radio {
      margin-top: 25px;
    }
  }

  .card-footer {
    position: relative;
  }

  .editable-table {
    td,
    th {
      padding: 7px 0 7px 10px;

      &:last-child {
        padding-right: 10px;
      }
    }

    th {
      vertical-align: top;
      font-size: 12px;
      font-weight: 500;
    }

    td {
      .form-control {
        padding: 3px;

        &.manual-input-field {
          padding-right: 27px;
        }
      }

      .form-control,
      .selectize-control,
      .selectize-input {
        font-size: 13px;
        line-height: 1;
        height: 27px;
      }

      .selectize-input {
        display: flex;
        align-items: center;

        .item {
          max-width: 90%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .duplicate-row,
      .delete-row {
        width: 27px;
        padding: 3px 0;
        font-size: 13px;
      }

      input[data-disabled='true'],
      select[data-disabled='true'] {
        display: none;

        + .selectize-control {
          display: none;
        }
      }

      .close-manual {
        display: none;
        position: relative;

        i {
          position: absolute;
          top: -23px;
          right: 10px;
          font-size: 20px;
          cursor: pointer;
        }
      }

      input[data-disabled='false'] + .close-manual {
        display: block;
      }
    }
  }

  .local-error {
    position: absolute;
    right: 15px;
    top: -10px;
    .alert-danger;
    display: inline-block;
    font-size: 13px;
    padding: 0px 3px;
    border: 1px solid #721c24;
    border-radius: 3px;
  }

  .export-btn {
    position: absolute;
    top: 17px;
    right: 1rem;
    padding: 2px 8px;
    height: 28.75px;
  }

  .edit-name-button {
    display: block;
    position: absolute;
    top: 4px;
    right: 8px;
    width: 20px;
    height: 20px;
    font-size: 15px;
    opacity: 0;
    transition: opacity 0.2s;

    &:after {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      content: '\f044';
      color: #666;
      width: 14px;
      height: 16px;
      margin: -8px 0 0 -7px;
      .fa;
      cursor: pointer;
    }
  }
}

.relation-block {
  margin-top: 5px;
  border-top: 1px solid #fff;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  text-align: left;

  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 3px;
  }
}

.result-container {
  .ol {
    margin-bottom: 1rem;
    padding: 10px 10px 10px 33px;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    counter-reset: section;
    list-style-type: none;
  }

  .li {
    position: relative;
    min-height: 1.5rem;

    &::before {
      position: absolute;
      top: 0;
      left: -2.2em;
      counter-increment: section;
      content: counter(section) '.';
      width: 2.2em;
      padding-right: 0.2em;
      text-align: right;
      pointer-events: none;
    }
  }

  .action-wrap {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 15px;
    }
  }

  .result-copy {
    position: relative;
  }
}

.table-responsive {
  &.has-selectize {
    overflow-x: unset;
  }
}

.j-container.disabled > * {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.8;
}

.selectized {
  &.not-valid + .selectize-control > .selectize-input {
    border-color: #f38b99;
  }
}
