#loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 90000;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;

  .spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }
}

.notify-text {
  font-size: 13px;
  font-weight: 400;
  color: @c-seconady;

  &[data-state='success'] {
    color: @c-success;
  }

  &[data-state='error'] {
    color: @c-danger;
  }

  &[data-state='notification'] {
    color: @c-seconady;
  }
}
