.login-page {
  //background: url(../img/bg_signin.jpg) center no-repeat;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  @media @mobile-landscape {
    height: auto;
  }
}

.login-page,
.register-page {
  background-color: transparent;
}

.login-page,
.reset-password-page,
.set-password-page,
.error-page {
  //background: url("../img/bg_signin.jpg") center no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.set-password-page {
  .form-signin {
  }
}

/* ---------- LOGIN ---------- */

#login {
  margin: 50px auto;
  width: 242px;
}

#login span {
  color: #676767;
  display: block;
  height: 48px;
  line-height: 48px;
  position: absolute;
  text-align: center;
  width: 36px;
}

#login input {
  border: none;
  height: 48px;
  outline: none;
}

#login input[type='text'] {
  background-color: #dedede;
  border-top: 1px solid #828282;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  color: #363636;
  margin-bottom: 20px;
  padding-left: 36px;
  width: 204px;
}

#login input[type='password'] {
  background-color: #dedede;
  border-top: 1px solid #828282;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  color: #363636;
  margin-bottom: 20px;
  padding-left: 36px;
  width: 204px;
}

#login input[type='submit'] {
  background: #e14d4d;
  border: 1px solid #391515;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  width: 240px;
}

#login input[type='submit']:hover {
  background: #f15958;
}
