.custom-tooltip-popover {
  position: absolute;
  will-change: transform;
  top: 15px;
  right: 10px;
  max-width: 320px;
  perspective: 700px;
  z-index: 9999;
  outline: 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  line-height: 1.4;
  z-index: 9999;
  transition-duration: 0ms;
  visibility: visible;
}

.tooltip-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  border-radius: 50%;
  text-align: center;
  //margin-left: 5px;
  margin-bottom: 2px;

  &.has-parents {
    color: #30bd8e;
    border-color: #30bd8e;
  }

  .tooltip {
    display: inline-block;
    top: 0;
    left: 0;
    line-height: 13px;
    width: 13px;
    text-align: center;
    opacity: 1;
    z-index: 1;
  }
}

.custom-tooltip {
  position: relative;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
  text-align: center;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.tooltip-inner {
  max-width: 350px;
  background-color: #1f1f1f;
}
