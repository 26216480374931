.edit-template-table {
  th.order {
    width: 70px;
  }

  th.select {
    width: 70px;
  }

  th.required {
    width: 95px;
  }

  th.type {
    width: 80px;
  }

  th.parent,
  th.name {
    width: 200px;
  }

  th.delimiter {
    width: 150px;
  }

  th.link {
    width: 150px;
  }

  th.delete {
    width: 55px;
  }

  td {
    padding: 0.35rem 0.6rem;
  }

  .template-table-block-item {
    display: flex;
  }

  .switcher {
    label {
      margin: 0;
    }
  }
}
